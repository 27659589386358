import { useUserContext } from "@igloo-be-omnipartners/hooks";
import React, { useEffect } from "react";
import { useLocationContext } from "./LocationProvider";

export const ExternalTokenLogin = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { setToken } = useUserContext();
  const { _user_token_ } = useLocationContext().query;

  useEffect(() => {
    if (_user_token_ && typeof _user_token_ === "string") {
      setToken(_user_token_);
    }
  }, [_user_token_]);

  return <>{children}</>;
};
