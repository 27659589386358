import { navigate, NavigateOptions, WindowLocation } from "@reach/router";
import { parse, stringify } from "qs";
import React, { createContext, useContext } from "react";

export const navigateTo = (
  to: string,
  params?: any,
  options?: NavigateOptions<{}>,
) => {
  if (params) {
    return navigate(linkTo(to, params), options);
  } else {
    return navigate(to, options);
  }
};

export const linkTo = (to: string, params?: any) =>
  `${to}#!${stringify(params)}`;

const parseQuery = (location: WindowLocation<any>) => ({
  ...parse(location.hash.replace(/^#!/, "")),
  ...parse(location.search.replace(/^\?/, "")),
});

interface ILocationContextValue {
  navigate: (
    to: string,
    params?: { [key: string]: any },
    options?: NavigateOptions<{}>,
  ) => void;
  query: any;
  location: WindowLocation<any>;
}

const LocationContextProvider = createContext<ILocationContextValue>(
  null as any,
);

export const LocationProvider = ({
  children,
  location,
}: {
  children: React.ReactNode;
  location: WindowLocation<any>;
}) => (
  <LocationContextProvider.Provider
    value={{
      query: parseQuery(location),
      location,
      navigate: navigateTo,
    }}
  >
    {children}
  </LocationContextProvider.Provider>
);

export const useLocationContext = () => useContext(LocationContextProvider);
