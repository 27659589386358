import { WindowLocation } from "@reach/router";
import React from "react";
import Helmet from "react-helmet";
import { AccessTokenLogin } from "./AccessTokenLogin";
import { ExternalTokenLogin } from "./ExternalTokenLogin";
import { LocationProvider } from "./LocationProvider";

export const withPage = <P extends object>(
  Component: React.ComponentType<P>,
  { title }: { title?: string } = {},
) =>
  class WithPage extends React.Component<P & { location: WindowLocation<any> }> {
    public render() {
      return (
        <LocationProvider location={this.props.location}>
          <AccessTokenLogin>
            <ExternalTokenLogin>
              {title && (
                <Helmet>
                  <title>{title}</title>
                </Helmet>
              )}
              <Component {...(this.props as P)} />
            </ExternalTokenLogin>
          </AccessTokenLogin>
        </LocationProvider>
      );
    }
  };
