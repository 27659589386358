import {
  useUserContext,
  useUserLoginByAccessToken,
  useUserLogout,
} from "@igloo-be-omnipartners/hooks";
import React, { createContext, useContext, useEffect, useState } from "react";
import { useUserCheckRemote } from "./hooks/useUserCheckRemote";
import { linkTo, navigateTo, useLocationContext } from "./LocationProvider";

const localContext = createContext({
  loading: false,
});

export const useIsAccessTokenLoginLoading = () => {
  return useContext(localContext).loading;
};

export const AccessTokenLogin = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [loading, setLoading] = useState(false);
  const { setToken } = useUserContext();
  const { loginByAccessToken } = useUserLoginByAccessToken();
  const { query, location } = useLocationContext();
  const { accessToken, extId, backUrl, ...rest } = query;
  const hasToken = accessToken && typeof accessToken === "string";
  const logout = useUserLogout();
  const loginBackUrl = linkTo(location.pathname, { ...rest, backUrl });
  const goToLoginState = {
    loginBackUrl,
    extId,
  };

  useUserCheckRemote({
    onNotLogged: () => {
      if (!hasToken && location.pathname !== "/") {
        logout();
        navigateTo(
          "/",
          {},
          {
            state: goToLoginState,
          },
        );
      }
    },
    onError: () => {
      if (!hasToken && location.pathname !== "/") {
        logout();
        navigateTo(
          "/",
          {},
          {
            state: goToLoginState,
          },
        );
      }
    },
  });

  useEffect(() => {
    if (accessToken && typeof accessToken === "string") {
      setLoading(true);
      loginByAccessToken({
        access_token: accessToken,
        userContextData: {
          partnerExtId: extId,
        },
      })
        .then(({ error, result }) => {
          if (result) {
            navigateTo(location.pathname, { ...rest, backUrl });
          } else if (error) {
            setToken(undefined);
            navigateTo(
              "/",
              {},
              {
                state: {
                  ...goToLoginState,
                  loginError: error,
                },
              },
            );
          }

          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          throw err;
        });
    }
  }, [accessToken, extId]);

  return (
    <localContext.Provider
      value={{
        loading,
      }}
    >
      {children}
    </localContext.Provider>
  );
};
