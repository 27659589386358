import { useUserCheckValidRemoteAuth } from "@igloo-be-omnipartners/hooks";
import { ApolloError } from "apollo-client";
import gql from "graphql-tag";
import { Locale } from "../../../../../__generated__/globalTypes";
import {
  RoleCheck,
  RoleCheckVariables,
  RoleCheck_result_error,
} from "../../components/__generated__/RoleCheck";

const RoleCheckQuery = gql`
  query RoleCheck($token: String!, $locale: Locale!) {
    result: userRole(token: $token, locale: $locale) {
      error {
        message
        code
      }
    }
  }
`;

export const useUserCheckRemote = ({
  onNotLogged = () => {},
  onError = console.error,
}: {
  onNotLogged?: () => void;
  onError?: (err: RoleCheck_result_error | ApolloError) => void;
} = {}) => {
  return useUserCheckValidRemoteAuth<RoleCheck, RoleCheckVariables>({
    query: RoleCheckQuery,
    variables: {
      locale: Locale.fr,
    },
    onNotLogged,
    onError,
  });
};
